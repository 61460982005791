import React, { useEffect } from 'react';
import './App.css';

import {
  Route,
  Switch,
  BrowserRouter,
  Redirect
} from 'react-router-dom'

const AutologinComponent = React.lazy(() => import('./Components/Autologin'))
const PrimerInicioComponent = React.lazy(() => import('./Components/PrimerInicio'))
const IndexComponent = React.lazy(() => import('./Components/Index'))
const CursoComponent = React.lazy(() => import('./Components/Curso/Curso'))
const PerfilComponent = React.lazy(() => import('./Components/PerfilContainer'))

const pathroute = window.location.pathname;
function App() {

  useEffect(() => {
    if(window.localStorage.getItem('cod') === null && pathroute != '/autologin.php') {
      window.location.href = "https://ctm-1.redbms.es/"
    }
  }, [])

  return (
    <>
      <BrowserRouter>
        <React.Suspense fallback={'Cargando...'}>
          <Switch>
            <Route path="/autologin.php" name="Autologin" render={props => <AutologinComponent {...props} />} />
            {/* <Route exact path="/curso" name="Curso" render={props => <CursoComponent {...props} />} /> */}
            <Route exact path="/primerinicio" name="Primer inicio" render={props => <PrimerInicioComponent {...props} />} />
            <Route exact path="/perfil" name="Perfil" render={props => <PerfilComponent {...props} />} />
            <Route exact path="/" name="Home" render={props => <IndexComponent {...props} />} />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
